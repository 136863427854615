/* unplugin-vue-components disabled */import "core-js/modules/es.function.name.js";
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mj-calendar"
};
var _hoisted_2 = {
  class: "mj-calendar__item mj-calendar__active"
};
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';
import { EVENTS } from '@/js/test-data';
export default {
  __name: 'MJCalendar',
  emits: ['init'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var router = useRouter();
    var store = useStore();
    var cData = reactive({
      activeCalendar: ''
    });
    /**
     * 更新日曆檢視日期
     */

    function updateCurrentCalendarViewDate() {
      var activeCalender = cData.activeCalendar; // 人員的日期不是用來顯示，故不更新

      if (router.currentRoute.value.name == 'timeGridPersonnel') return;

      if (activeCalender && store.state.calendar.selectedDate) {
        activeCalender.calendarApi.gotoDate(store.state.calendar.selectedDate);
      }
    }

    onMounted(function () {
      emit('init', {
        updateCurrentCalendarViewDate: updateCurrentCalendarViewDate
      });
    });
    onActivated(function () {// var mjc = document.querySelector('.mj-calendar')
      // mjc.style.opacity = 0
      // window.dispatchEvent(new CustomEvent('resize'))
      // setTimeout(()=>{
      //     mjc.style.opacity = 1
      // },300)
    });
    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view, {
        onInit: _cache[0] || (_cache[0] = function ($event) {
          return _unref(cData).activeCalendar = $event;
        })
      }, {
        default: _withCtx(function (_ref2) {
          var Component = _ref2.Component;
          return [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))];
        }),
        _: 1
      })])]);
    };
  }
};