import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "calendar-panel"
};
var _hoisted_2 = {
  class: "calendar-panel__left"
};
var _hoisted_3 = {
  class: "calendar-panel__right"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "left")]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "right")])]);
}